import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/home';
import AdsPage from './components/ads';
import WebApp from './components/webapp'; // Импортируем компонент вашего Mini App
import './App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={< HomePage />} />
                <Route path="/ads" element={< AdsPage />} />
                <Route path="/webapp" element={<WebApp />} /> {/* Новый маршрут */}
            </Routes>
        </Router>
    );
}

export default App;
