import React, { useState, useEffect } from "react";
import "./ChannelsList.css";

function ChannelsList() {
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [myChannels, setMyChannels] = useState([]);
    const [promotionForm, setPromotionForm] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("http://localhost:4000/api/channels/enabled")
            .then((response) => response.json())
            .then((data) => {
                setChannels(data.channels);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });

        fetch("http://localhost:4000/api/channels?userId=1056371912")
            .then((response) => response.json())
            .then((data) => {
                setMyChannels(data.channels);
            })
            .catch((error) => console.error("Ошибка загрузки каналов пользователя:", error));
    }, []);

    const handleProposeVP = () => {
        setPromotionForm({
            fromChannelId: null,
            useAnyPost: false,
            fromChannelPost: null,
        });
    };

    const handleSubmitPromotion = (formData) => {
        fetch("http://localhost:4000/api/promotion", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ...formData,
                toChannelId: selectedChannel.channelId,
            }),
        })
            .then((response) => response.json())
            .then(() => {
                alert("Предложение ВП успешно отправлено!");
                setPromotionForm(null);
            })
            .catch((error) => console.error("Ошибка при создании предложения ВП:", error));
    };

    if (loading) return <p>Загрузка...</p>;
    if (error) return <p>Ошибка: {error}</p>;

    if (promotionForm) {
        const { fromChannelId } = promotionForm;
        return (
            <div className="promotion-form-container channel-settings">
                <h2>Предложить ВП</h2>

                {/* Шаг 1: Выбор своего канала */}
                <label>Выберите свой канал:</label>
                <select
                    value={fromChannelId || ""}
                    onChange={(e) =>
                        setPromotionForm((prev) => ({
                            ...prev,
                            fromChannelId: e.target.value,
                        }))
                    }
                >
                    <option value="">Выберите канал</option>
                    {myChannels.map((channel) => (
                        <option key={channel.channelId} value={channel.channelId}>
                            {channel.name}
                        </option>
                    ))}
                </select>

                {/* Шаг 2: Ссылка на пост */}
                {fromChannelId && (
                    <>
                        <label>
                            <input
                                type="checkbox"
                                checked={promotionForm.useAnyPost || false}
                                onChange={(e) =>
                                    setPromotionForm((prev) => ({
                                        ...prev,
                                        useAnyPost: e.target.checked,
                                    }))
                                }
                            />
                            Взять любой пост
                        </label>

                        {!promotionForm.useAnyPost && (
                            <input
                                type="text"
                                placeholder="Введите ссылку на пост"
                                onChange={(e) =>
                                    setPromotionForm((prev) => ({
                                        ...prev,
                                        fromChannelPost: e.target.value,
                                    }))
                                }
                            />
                        )}
                    </>
                )}

                {/* Кнопки */}
                <button
                    onClick={() => handleSubmitPromotion(promotionForm)}
                    disabled={!promotionForm.fromChannelId || (!promotionForm.useAnyPost && !promotionForm.fromChannelPost)}
                >
                    Отправить предложение
                </button>
                <button onClick={() => setPromotionForm(null)}>Отмена</button>
            </div>
        );
    }

    if (selectedChannel) {
        return (
            <div className="selected-channel-container channel-settings">
                <h2>{selectedChannel.name}</h2>
                <button onClick={handleProposeVP}>Предложить ВП</button>
                <button className="back-button" onClick={() => setSelectedChannel(null)}>
                    Назад
                </button>
            </div>
        );
    }

    return (
        <div className="channels-list-container">
            <h2>Список каналов</h2>
            {channels.length > 0 ? (
                <div className="channels-grid channel-list">
                    {channels.map((channel) => (
                        <div
                            className="channel-card"
                            key={channel.id}
                            onClick={() => setSelectedChannel(channel)}
                        >
                            <img
                                src={`http://localhost:4000/api/proxy/avatar/${channel.avatar}`}
                                alt={`${channel.name} avatar`}
                                className="channel-avatar"
                            />
                            <div className="channel-info">
                                <h3>{channel.name}</h3>
                            </div>
                            <span className="channel-subscribers">{channel.subscribers} подписчиков</span>
                        </div>
                    ))}
                </div>
            ) : (
                <p>Нет включённых каналов.</p>
            )}
        </div>
    );
}

export default ChannelsList;
