import React, { useState, useEffect } from "react";
import Home from "./webapp/Home";
import ChannelsList from "./webapp/ChannelsList"; // Подключаем новый компонент
import MyChannels from "./webapp/MyChannels";
import "./webapp/WebApp.css"; // Подключаем стили

function WebApp() {
    const [page, setPage] = useState("home");

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.expand(); // Разворачиваем WebView
    }, []);

    const renderPage = () => {
        switch (page) {
            case "home":
                return <Home />;
            case "channels":
                return <ChannelsList />; // Используем новый компонент для списка каналов
            case "myChannels":
                return <MyChannels />;
            default:
                return <Home />;
        }
    };

    return (
        <div className="webapp-container">
            <div className="content">{renderPage()}</div>
            <div className="nav-menu">
                <button
                    className={`nav-button ${page === "home" ? "active" : ""}`}
                    onClick={() => setPage("home")}
                >
                    Главная
                </button>
                <button
                    className={`nav-button ${page === "channels" ? "active" : ""}`}
                    onClick={() => setPage("channels")}
                >
                    Список каналов
                </button>
                <button
                    className={`nav-button ${page === "myChannels" ? "active" : ""}`}
                    onClick={() => setPage("myChannels")}
                >
                    Мои каналы
                </button>
            </div>
        </div>
    );
}

export default WebApp;
