import React, { useState, useEffect } from "react";
import "./MyChannels.css";

function MyChannels() {
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [showAddChannelForm, setShowAddChannelForm] = useState(false);
    const [newChannelId, setNewChannelId] = useState("");
    const [newChannelLink, setNewChannelLink] = useState("");
    const [editLink, setEditLink] = useState("");
    const userId = 1056371912;

    useEffect(() => {
        if (userId) {
            fetch(`http://localhost:4000/api/channels?userId=${userId}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Ошибка при загрузке каналов.");
                    }
                    return response.json();
                })
                .then((data) => setChannels(data.channels))
                .catch((error) => {
                    console.error("Ошибка загрузки каналов:", error.message);
                    alert("Не удалось загрузить каналы. Проверьте данные или попробуйте позже.");
                });
        }
    }, [userId]);

    const handleAddChannel = () => {
        if (!newChannelId || !newChannelLink) {
            alert("Введите ID/username канала и ссылку.");
            return;
        }

        fetch("http://localhost:4000/api/channels", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ chatId: newChannelId, userId, link: newChannelLink }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((data) => {
                        throw new Error(data.error || "Ошибка добавления канала.");
                    });
                }
                return response.json();
            })
            .then((data) => {
                alert(data.message);
                setChannels([...channels, data.channel]);
                setNewChannelId("");
                setNewChannelLink("");
                setShowAddChannelForm(false);
            })
            .catch((error) => {
                console.error("Ошибка добавления канала:", error.message);
                alert(error.message);
            });
    };

    const handleUpdateLink = (channelId) => {
        if (!editLink) {
            alert("Введите новую ссылку.");
            return;
        }

        fetch(`http://localhost:4000/api/channels/${channelId}/link`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ link: editLink }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((data) => {
                        throw new Error(data.error || "Ошибка обновления ссылки.");
                    });
                }
                return response.json();
            })
            .then((data) => {
                alert(data.message);
                setChannels(
                    channels.map((channel) =>
                        channel.channelId === channelId
                            ? { ...channel, link: data.channel.link }
                            : channel
                    )
                );
                setEditLink("");
                setSelectedChannel(null);
            })
            .catch((error) => {
                console.error("Ошибка обновления ссылки:", error.message);
                alert(error.message);
            });
    };

    const handleDeleteChannel = (channelId) => {
        if (window.confirm("Вы уверены, что хотите удалить этот канал?")) {
            fetch(`http://localhost:4000/api/channels/${channelId}?userId=${userId}`, {
                method: "DELETE",
            })
                .then((response) => {
                    if (!response.ok) {
                        return response.json().then((data) => {
                            throw new Error(data.error || "Ошибка удаления канала.");
                        });
                    }
                    return response.json();
                })
                .then(() => {
                    setChannels(channels.filter((channel) => channel.channelId !== channelId));
                    alert("Канал успешно удалён.");
                })
                .catch((error) => {
                    console.error("Ошибка удаления канала:", error.message);
                    alert(error.message);
                });
        }
    };

    if (selectedChannel) {
        return (
            <div className="channel-settings">
                <h2>Настройки канала</h2>
                <h3>{selectedChannel.name}</h3>
                <p>ID канала: {selectedChannel.channelId}</p>
                <p>Подписчики: {selectedChannel.subscribers}</p>
                <p>Ссылка: {selectedChannel.link || "Нет ссылки"}</p>

                <input
                    type="text"
                    placeholder="Введите новую ссылку"
                    value={editLink}
                    onChange={(e) => setEditLink(e.target.value)}
                />
                <button onClick={() => handleUpdateLink(selectedChannel.channelId)}>
                    Обновить ссылку
                </button>
                <button onClick={() => setSelectedChannel(null)}>Назад</button>
                <button onClick={() => handleDeleteChannel(selectedChannel.channelId)}>
                    Удалить канал
                </button>
            </div>
        );
    }

    return (
        <div className="my-channels-container">
            <h2>Мои каналы</h2>
            {channels.length > 0 ? (
                <div className="channel-list">
                    {channels.map((channel) => (
                        <div
                            className="channel-card"
                            key={channel.id}
                            onClick={() => setSelectedChannel(channel)}
                        >
                            {channel?.avatar ? (
                                <img
                                    src={`http://localhost:4000/api/proxy/avatar/${channel.avatar}`}
                                    alt={`${channel.name} avatar`}
                                    className="channel-avatar"
                                />
                            ) : (
                                <div className="channel-avatar-placeholder">No Avatar</div>
                            )}
                            <h3>{channel?.name || "Unknown Channel"}</h3>
                            <p>Подписчики: {channel?.subscribers || 0}</p>
                            <p>ID канала: {channel?.channelId || "N/A"}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p>У вас пока нет добавленных каналов.</p>
            )}
            <button
                className="add-channel-button"
                onClick={() => setShowAddChannelForm(!showAddChannelForm)}
            >
                {showAddChannelForm ? "Скрыть форму" : "Добавить канал"}
            </button>
            {showAddChannelForm && (
                <div className="add-channel-form">
                    <label htmlFor="channel-id">Введите ID или username канала:</label>
                    <input
                        id="channel-id"
                        type="text"
                        placeholder="Например: @example_channel"
                        value={newChannelId}
                        onChange={(e) => setNewChannelId(e.target.value)}
                    />

                    <label htmlFor="channel-link">Введите ссылку на канал:</label>
                    <input
                        id="channel-link"
                        type="text"
                        placeholder="Например: https://t.me/example_channel"
                        value={newChannelLink}
                        onChange={(e) => setNewChannelLink(e.target.value)}
                    />

                    <button onClick={handleAddChannel}>Сохранить</button>
                </div>
            )}
        </div>
    );
}

export default MyChannels;
