import React from "react";

function Home() {
    const tg = window.Telegram.WebApp;
    const user = tg.initDataUnsafe?.user || {};
    const name = user.first_name || "Гость";
    const avatar = user.photo_url || "https://via.placeholder.com/100";

    return (
        <div>
            <h2>Главная страница</h2>
            <p>Привет, {name}!</p>
            <img
                src={avatar}
                alt="Avatar"
                style={{ borderRadius: "50%", marginTop: "10px" }}
                width="100"
                height="100"
            />
        </div>
    );
}

export default Home;
